import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import WhoAmI from "./components/WhoAmI";
import CentralLayout from "./components/CentralLayout";
import "./App.css";

function App() {
  return (
    <Router>
      <Navbar />
      <div className="app">
        <WhoAmI />
        <CentralLayout />
      </div>
    </Router>
  );
}

export default App;
