import React from "react";

const OtherInfo = () => {
  const otherInfo = [
    "Amateur triathlete since 2017.",
    "Coach certification from the Mexican Triathlon Federation (2019).",
    "Founder of Runner’s High Club and TEAM in Madrid.",
    "Mountaineering: Summited top 5 mountains of Mexico above 5,000m.",
  ];

  return (
    <div>
      <h2>Other Info</h2>
      <ul>
        {otherInfo.map((info, index) => (
          <li key={index}>{info}</li>
        ))}
      </ul>
    </div>
  );
};

export default OtherInfo;
