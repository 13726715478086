import React from "react";

const Skills = () => {
  const skills = [
    "Sales and Negotiations: Securing and managing high-value deals.",
    "Cold sales: Cold calling strategies to increase the sales funnel.",
    "Strategic Partnership Development: Building and maintaining long-term relationships.",
    "Data Analysis: Utilizing analytics and market research for decision-making.",
    "Technological Proficiency: Google Workspace, Office Suite, Canva, Salesforce, WordPress, Active Campaign, SAP, and ERP systems.",
  ];

  return (
    <div>
      <h2>Skills</h2>
      <ul>
        {skills.map((skill, index) => (
          <li key={index}>{skill}</li>
        ))}
      </ul>
    </div>
  );
};

export default Skills;
