import React from "react";

const Education = () => {
  const educationDetails = [
    {
      degree: "Master in Business Administration",
      institution: "UCAM - Universidad Católica de Murcia",
      dates: "November 2022 - June 2023",
    },
    {
      degree: "Bachelor in Commerce and International Business",
      institution: "Banking and Commercial School (EBC)",
      dates: "2014 - 2018",
    },
  ];

  return (
    <div>
      <h2>Education</h2>
      <ul>
        {educationDetails.map((edu, index) => (
          <li key={index}>
            <strong>{edu.degree}</strong>
            <br />
            {edu.institution}
            <br />
            <span>{edu.dates}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Education;
