import React from "react";
import "./WhoAmI.css";

const WhoAmI = () => {
  return (
    <section className="who-am-i" id="who-am-i">
      <h1>About Me</h1>
      <p>
        Dynamic Sales Professional with a strong background in International
        Business, sales, digital marketing, and sports. Leveraging a blend of
        strategic sales, marketing, and commercial strategies. I am an avid
        triathlete and mountaineer, bringing a competitive spirit and relentless
        determination to every challenge.
      </p>
    </section>
  );
};

export default WhoAmI;
