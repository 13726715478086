import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="logo">
        <Link to="/">Iker Ordoñana</Link>
      </div>
      <ul className="nav-links">
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <a href="#who-am-i">About Me</a>
        </li>
        <li>
          <a href="#timeline">Timeline</a>
        </li>
        <li>
          <a href="#qa">Q&A</a>
        </li>
        <li>
          <Link to="/sports">Sports</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
