import React from "react";

const Certifications = () => {
  const certifications = [
    {
      name: "Google Digital Marketing & E-commerce Professional Certificate",
      provider: "Google & Coursera",
    },
  ];

  return (
    <div>
      <h2>Certifications</h2>
      <ul>
        {certifications.map((cert, index) => (
          <li key={index}>
            <strong>{cert.name}</strong> - {cert.provider}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Certifications;
