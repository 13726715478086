import React, { useState } from "react";
import "./QASection.css";

const QASection = () => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const questions = [
    {
      id: 1,
      question: "What inspired your career path?",
      video: "https://via.placeholder.com/300x200.mp4", // Replace with your video URL
    },
    {
      id: 2,
      question: "What are your key strengths?",
      video: "https://via.placeholder.com/300x200.mp4",
    },
    {
      id: 3,
      question: "Where do you see yourself in 5 years?",
      video: "https://via.placeholder.com/300x200.mp4",
    },
  ];

  return (
    <section className="qa-section">
      <h2>Interactive Q&A</h2>
      <div className="qa-container">
        <div className="tv-screen">
          {selectedQuestion ? (
            <video src={selectedQuestion.video} controls autoPlay />
          ) : (
            <p className="placeholder">Select a question to see the answer</p>
          )}
        </div>
        <ul className="question-list">
          {questions.map((q) => (
            <li
              key={q.id}
              className={`question-item ${
                selectedQuestion?.id === q.id ? "active" : ""
              }`}
              onClick={() => setSelectedQuestion(q)}
            >
              {q.question}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default QASection;
