import React, { useState } from "react";
import "./CentralLayout.css";
import Timeline from "./Timeline";
import Education from "./Education";
import Certifications from "./Certifications";
import Skills from "./Skills";
import OtherInfo from "./OtherInfo";
import QASection from "./QASection";

const CentralLayout = () => {
  const [activeSection, setActiveSection] = useState(null);

  const sections = [
    { id: "education", label: "Education", component: <Education /> },
    { id: "certifications", label: "Certifications", component: <Certifications /> },
    { id: "skills", label: "Skills", component: <Skills /> },
    { id: "otherinfo", label: "Other Info", component: <OtherInfo /> },
    { id: "timeline", label: "Timeline", component: <Timeline /> },
    { id: "qa", label: "Q&A", component: <QASection /> },
  ];

  return (
    <div className="central-layout">
      <div className="orbit">
        {sections.map((section, index) => (
          <div
            key={section.id}
            className={`orbit-item ${activeSection === section.id ? "active" : ""}`}
            style={{
              transform: `rotate(${(360 / sections.length) * index}deg) translate(20rem) rotate(-${(360 / sections.length) * index}deg)`,
            }}
            onClick={() => setActiveSection(section.id)}
          >
            {section.label}
          </div>
        ))}
      </div>
      {activeSection && (
        <div className="active-section">
          <button className="close-button" onClick={() => setActiveSection(null)}>
            Close
          </button>
          {sections.find((section) => section.id === activeSection).component}
        </div>
      )}
    </div>
  );
};

export default CentralLayout;
