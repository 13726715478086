import React, { useState } from "react";
import "./Timeline.css";

const Timeline = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);

  const timelineEvents = [
    {
      id: 1,
      year: "2019 (Apr) - 2021 (May)",
      title: "Senior Account Executive at Marsh",
      description: "Achieved 150% portfolio growth and expanded client portfolios.",
      media: null,
    },
    {
      id: 2,
      year: "2021 (Jun) - 2022 (May)",
      title: "Sub Manager of New Business at Lockton",
      description: "Exceeded sales goals by 20%, directed teams, and maintained client retention.",
      media: null,
    },
    {
      id: 3,
      year: "2022 (Aug - Oct)",
      title: "Google Workspace Support Services Manager",
      description: "Managed AAA client portfolios and retained 100% of clients.",
      media: null,
    },
    {
      id: 4,
      year: "2023 - Present",
      title: "Business Development Manager at ATA Insights",
      description:
        "Led sponsorship efforts, increasing revenue by 15%. Developed strategic products and managed a 100% client portfolio.",
      media: null,
    },
  ];

  const closeModal = () => {
    setSelectedEvent(null);
  };

  return (
    <section className="timeline-section">
      <h2>Professional Timeline</h2>
      <div className="timeline">
        {timelineEvents.map((event, index) => (
          <div
            key={event.id}
            className="timeline-event"
            onClick={() => setSelectedEvent(event)}
            style={{ left: `${(index / (timelineEvents.length - 1)) * 100}%` }}
          >
            <div className="timeline-marker">
              <span className="timeline-date">{event.year}</span>
            </div>
          </div>
        ))}
        <div className="timeline-line"></div>
      </div>
      {selectedEvent && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3>{selectedEvent.title}</h3>
            <p>{selectedEvent.description}</p>
            {selectedEvent.media ? (
              <video src={selectedEvent.media} controls />
            ) : (
              <p style={{ fontStyle: "italic", color: "gray" }}>
                Media will be added soon.
              </p>
            )}
            <button onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
    </section>
  );
};

export default Timeline;
